import React from 'react'
import _ from 'lodash'
import { FormattedHTMLMessage } from 'react-intl'

import { TitleCard } from '../components/common/Index.styled'
import Section from '../components/common/Section'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import Layout from '../components/Layout'

import image_background from '../images/blog_background.jpg'

const LastYears = props => (
  <Layout
    title="Últimos años"
    description="Somos de las pocas que firmas que prestan servicios en múltiples idiomas: Inglés, español, chino y portugués."
    {...props}
  >
    <SectionHeaderImage image={image_background} position="0" />
    <Section height="650px">
      <div
        style={{ textAlign: 'justify', maxWidth: '800px', margin: '0 auto' }}
      >
        <TitleCard forwardedAs="h1">
          <FormattedHTMLMessage id="last_years.title" />
        </TitleCard>
        <p>
          <FormattedHTMLMessage id="last_years.description" />
        </p>
        <ul>
          {_.times(21, Number).map(i => (
            <li key={i}>
              <FormattedHTMLMessage id={`last_years.list1.item${i + 1}`} />
            </li>
          ))}
        </ul>
      </div>
    </Section>
  </Layout>
)

export default LastYears
